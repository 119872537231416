import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Image } from "react-bootstrap";
import axios from "axios";
import { IBlog } from ".";
import BreadcrumbsComponent from "../../../BreadCrumbs";

const BlogDetails = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blogDetail, setBlogDetail] = useState<IBlog | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchBlogDetails = async (slug: string) => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get<IBlog>(
        `${process.env.REACT_APP_API_URL}/blogs/${slug}/`,
        config
      );
      return response.data;
    } catch (err) {
      const error = err as {
        response?: { data: { detail?: string }; status?: number };
      };
      const errorMessage = error.response?.data.detail || "An error occurred";
      throw new Error(errorMessage);
    }
  };

  useEffect(() => {
    const getBlogDetails = async () => {
      if (slug) {
        try {
          const data = await fetchBlogDetails(slug);
          setBlogDetail(data);
        } catch (err: any) {
          setError(`Failed to fetch blog details: ${err.message}`);
        } finally {
          setLoading(false);
        }
      }
    };

    getBlogDetails();
  }, [slug]);

  if (loading) {
    return <div className="container mt-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="container mt-4">
        Failed to fetch blog details: {error}
      </div>
    );
  }

  if (!blogDetail) {
    return <div className="container mt-4">Blog not found</div>;
  }

  const breadcrumbItems = [
    { href: "/", label: "Home" },
    { href: "/blogs/all", label: "Blogs" },
  ];

  return (
    <div className="container mt-5">
      <BreadcrumbsComponent items={breadcrumbItems} />
      <h1>{blogDetail.title}</h1>
      <Image
        src={blogDetail.featured_image}
        alt={blogDetail.title}
        className="img-fluid max-w-full h-auto"
      />
      <div dangerouslySetInnerHTML={{ __html: blogDetail.content }} />
    </div>
  );
};

export default BlogDetails;
