import { Link } from "react-router-dom";

const PageNotFound = () => (
  <>
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1> 404: Not Found</h1>
      {/* goback to home page */}
      <Link to="/" className="">
        <button className="btn btn-primary d-flex justify-content-center align-items-center">
          {/* back arrow */}
          <i className="fa-solid fa-caret-left fs-4"></i>
          Go back to Home
        </button>
      </Link>
    </div>

  </>
);

export default PageNotFound;
