import { Link, useLocation } from "react-router-dom";
import BlogLayout from "./BlogLayout";
import Pagination from "../../../BlogPagination";
import BreadcrumbsComponent from "../../../BreadCrumbs";
import { useEffect, useState } from "react";
import axios from "../../../../api/apiclient";

export interface IBlog {
  slug: string;
  title: string;
  short_description: string;
  featured_image: string;
  created_at: string;
  content: string;
  meta_title: string;
  meta_keywords: string;
  meta_description: string;
}

export interface IBlogListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IBlog[];
}

export default function Blogs({ heading }: { heading?: string }) {
  const blogsPerPage = 6;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get<IBlogListResponse>(
          `${process.env.REACT_APP_API_URL}/blogs/`
        );
        setBlogs(response.data.results);
        setTotalPages(Math.ceil(response.data.count / blogsPerPage));
      } catch (error) {
        console.error("Error fetching blog list:", error);
      }
    };

    fetchBlogs();
  }, []);

  const startIndex = (currentPage - 1) * blogsPerPage;
  const currentBlogs = blogs.slice(startIndex, startIndex + blogsPerPage);

  const breadcrumbItems = [
    { href: "/", label: "Home" },
    { href: "/blogs/all", label: "Blogs" },
  ];

  return (
    <div>
      <section className="container mt-5 ">
        <BreadcrumbsComponent items={breadcrumbItems} />
        <div className="d-flex justify-content-between align-items-center w-100 mb-4">
          <h2 className="font-weight-bold text-capitalize text-dark mb-0">
            {heading || "Featured Blogs"}
          </h2>
          <Link
            to="/blogs/all"
            className="mb-0 text-capitalize font-weight-bold text-primary"
          >
            View All
          </Link>
        </div>
        <div className="row">
          {currentBlogs.map((blog) => (
            <article className="col-12 col-sm-6 col-md-4 mb-4" key={blog.slug}>
              <BlogLayout blogs={blog} />
            </article>
          ))}
        </div>
      </section>

      <Pagination TotalPage={totalPages} />
    </div>
  );
}
