import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IBlog } from ".";
import axios from "../../../../api/apiclient";
import BreadcrumbsComponent from "../../../BreadCrumbs";
import BlogLayout from "./BlogLayout";
import Pagination from "../../../BlogPagination";

export async function generateMetadata() {
  return {
    title: "All Blogs",
    description: "Explore our collection of expert blogs and tutorials.",
  };
}

const BlogPage = () => {
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const blogsPerPage = 6; // Number of blogs to display per page
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get("page") || "1", 10);

  useEffect(() => {
    const fetchBlogs = async () => {
      const token = localStorage.getItem("asp-authv2");
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: `Bearer ${JSON.parse(token).access}` }),
        },
      };

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/blogs/`,
          config
        );
        setBlogs(res.data.results);
        setTotalPages(Math.ceil(res.data.count / blogsPerPage));
      } catch (error) {
        console.error("Error fetching blog list", error);
      }
    };

    fetchBlogs();
  }, []);

  const breadcrumbItems = [
    { href: "/", label: "Home" },
    { href: "/blogs/all", label: "Blogs" },
  ];

  return (
    <article className=" text-dark">
      <BreadcrumbsComponent items={breadcrumbItems} />
      <div className="container">
        <h1 className="mt-4 font-weight-bold">All Blogs</h1>
        <p>Explore our collection of insightful and informative blogs!</p>
      </div>

      <div className="container mt-5">
        <div className="row">
          {blogs.map((blog) => (
            <div key={blog.slug} className="col-12 col-sm-6 col-lg-4 mb-4">
              <BlogLayout blogs={blog} />
            </div>
          ))}
        </div>
      </div>

      <Pagination TotalPage={totalPages} />
    </article>
  );
};

export default BlogPage;
