"use client";
import { Link, useNavigate, useLocation } from "react-router-dom";

export interface IPagination<T> {
  count: string;
  next?: string;
  previous?: string;
  results: T[];
}

export default function Pagination({ TotalPage }: { TotalPage: number }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Page = searchParams.get("page") || "1";

  const updateSearchParams = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <nav className="d-flex justify-content-center mt-4">
      <ul className="pagination">
        {/* Previous Button */}
        {parseInt(Page) > 1 && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => updateSearchParams(parseInt(Page) - 1)}
              disabled={parseInt(Page) <= 1}
              aria-label="Previous Page"
            >
              &laquo;
            </button>
          </li>
        )}

        {/* Page Number Buttons */}
        {Array.from(Array(TotalPage).keys()).map((item) => {
          const pageNumber = item + 1;
          searchParams.set("page", pageNumber.toString());
          const isActive = Page === pageNumber.toString();

          return (
            <li className={`page-item ${isActive ? "active" : ""}`} key={item}>
              <Link
                to={`${location.pathname}?${searchParams.toString()}`}
                className="page-link"
                aria-label={`Page ${pageNumber}`}
              >
                {pageNumber}
              </Link>
            </li>
          );
        })}

        {/* Next Button */}
        {parseInt(Page) < TotalPage && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => updateSearchParams(parseInt(Page) + 1)}
              disabled={parseInt(Page) >= TotalPage}
              aria-label="Next Page"
            >
              &raquo;
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}
