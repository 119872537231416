import { FC } from "react";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import { Pagination as IPagination } from "../../models/paginationModel";

interface Props {
  data: IPagination;
  url: string;
  offset: number;
}

const CustomPagination: FC<Props> = ({
  data: { count, next, previous },
  offset,
  url,
}) => {
  const limit = 2; // Assuming limit per page is 2 items
  const currentPage = Math.floor(offset / limit) + 1;
  const totalPages = Math.ceil(count / limit);

  // Function to limit the number of visible pages and add ellipses
  const getPagination = () => {
    const pg = [];
    const maxVisiblePages = 5; // Number of pages to show around current page

    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 2, totalPages);

    if (totalPages > maxVisiblePages) {
      if (currentPage <= 3) {
        // Case when the current page is near the start
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + 2 >= totalPages) {
        // Case when the current page is near the end
        startPage = totalPages - (maxVisiblePages - 1);
        endPage = totalPages;
      }
    }

    // Show the first page if it's not in the visible range
    if (startPage > 1) {
      pg.push(
        <li className="page-item" key={1}>
          <Link className="page-link" to={`${url}?offset=0&limit=${limit}`}>
            1
          </Link>
        </li>
      );
      if (startPage > 2) {
        pg.push(
          <li className="page-item disabled" key="start-ellipsis">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    // Generate the visible page numbers
    for (let i = startPage; i <= endPage; i++) {
      const newOffset = (i - 1) * limit;
      pg.push(
        <li
          className={`page-item ${i === currentPage ? "active" : ""}`}
          key={i}
        >
          <Link
            className="page-link"
            to={`${url}?offset=${newOffset}&limit=${limit}`}
          >
            {i}
          </Link>
        </li>
      );
    }

    // Show the last page if it's not in the visible range
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pg.push(
          <li className="page-item disabled" key="end-ellipsis">
            <span className="page-link">...</span>
          </li>
        );
      }
      const lastPageOffset = (totalPages - 1) * limit;
      pg.push(
        <li className="page-item" key={totalPages}>
          <Link
            className="page-link"
            to={`${url}?offset=${lastPageOffset}&limit=${limit}`}
          >
            {totalPages}
          </Link>
        </li>
      );
    }

    return pg;
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-4">
      <Pagination>
        {/* Previous Page Button */}
        <li className={`page-item ${!previous ? "disabled" : ""}`}>
          <Link
            className="page-link"
            to={
              previous ? `${url}?offset=${offset - limit}&limit=${limit}` : "#"
            }
          >
            ‹
          </Link>
        </li>

        {/* Page Numbers */}
        {getPagination()}

        {/* Next Page Button */}
        <li className={`page-item ${!next ? "disabled" : ""}`}>
          <Link
            className="page-link"
            to={next ? `${url}?offset=${offset + limit}&limit=${limit}` : "#"}
          >
            ›
          </Link>
        </li>
      </Pagination>
    </div>
  );
};

export default CustomPagination;
