import { format } from "date-fns";
import { Link } from "react-router-dom";
import { IBlog } from ".";

export default function BlogLayout({ blogs }: { blogs: IBlog }) {
  return (
    <div className="card h-100">
      <Link to={`/blogs/${blogs.slug}`}>
        <img
          src={blogs.featured_image}
          className="card-img-top"
          alt={blogs.title}
          style={{
            height: "200px",
            objectFit: "cover",
            width: "100% !important",
          }} // example with !important
        />
      </Link>
      <div className="card-body d-flex flex-column">
        <Link to={`/blogs/${blogs.slug}`}>
          <h5
            className="card-title text-dark"
            style={{ color: "#000 !important" }}
          >
            {blogs.title}
          </h5>
        </Link>
        <p className="card-text">{blogs.short_description}</p>
        <span className="d-inline-block w-100 text-capitalize text-muted font-weight-semibold text-xs mb-2">
          {format(new Date(blogs.created_at), "MMMM dd, yyyy")}
        </span>

        <Link to={`/blogs/${blogs.slug}`} className="btn btn-primary mt-auto">
          Read More
        </Link>
      </div>
    </div>
  );
}
