import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../api/apiclient";
import PageNotFound from "./PageNotFound";

interface INavDetails {
  id: number;
  slug: string;
  title: string;
  created_at?: string;
  updated_at?: string;
  banner_image?: string;
  content?: string;
}

const fetchNavDetails = async (slug: string) => {
  try {
    const response = await axios.get<INavDetails>(
      `${process.env.REACT_APP_API_URL}/pages/${slug}/`
    );
    return response.data;
  } catch (err) {
    const error = err as {
      response?: { data: { detail?: string }; status?: number };
    };
    const errorMessage = error.response?.data.detail || "An error occurred";
    throw new Error(errorMessage);
  }
};

export default function NavDetails() {
  const { slug } = useParams<{ slug: string }>();
  const [product, setProduct] = useState<INavDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getProductDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        if (slug) {
          const data = await fetchNavDetails(slug);
          setProduct(data);
        }
      } catch (err: any) {
        setError(`Failed to fetch product details: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    getProductDetails();
  }, [slug]);

  if (loading) {
    return <div className="container mt-4">Loading...</div>;
  }

  if (error) {
    return <PageNotFound />;
  }

  if (!product || !product.title || !product.content || !product.banner_image) {
    return <PageNotFound />;
  }

  return (
    <div className="container">
      <div className="mb-4">
        <div className="p-3 bg-white text-center">
          <h3 className="font-weight-bold mb-5">{product.title}</h3>
          {product.banner_image && (
            <div className="d-flex justify-content-center">
              <img
                src={product.banner_image}
                alt={product.title}
                className="img-fluid"
                style={{ height: "500px", width: "100%", objectFit: "cover" }}
              />
            </div>
          )}
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: product.content ?? "" }}
          />
        </div>
      </div>
    </div>
  );
}
