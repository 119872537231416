import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

export interface IBreadCrumb {
  href: string;
  label: string;
}

export interface IBreadCrumbComponent {
  items: IBreadCrumb[];
}

const BreadcrumbsComponent: React.FC<IBreadCrumbComponent> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb" className="mb-4">
      <div className="container d-flex justify-content-center">
        <ol className="breadcrumb">
          {items.map((item, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${
                index === items.length - 1 ? "active" : ""
              }`}
              aria-current={index === items.length - 1 ? "page" : undefined}
            >
              {index === items.length - 1 ? (
                item.label
              ) : (
                <Link to={item.href}>{item.label}</Link>
              )}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
};

export default BreadcrumbsComponent;
